// Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api/api';
import '../styles/Login.css';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Track loading state

    useEffect(() => {
        // Check login status when component mounts
        const checkLoginStatus = async () => {
            try {
                const response = await api.getData('/login');
                if (response.responseType === 'success') {
                    navigate('/'); // Route to the dashboard if already logged in
                } else {
                    setIsLoading(false); // Update loading state once checked
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false); // Update loading state in case of error
            }
        };

        checkLoginStatus(); // Call the function
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Make a POST request to your API with JSON data
            const response = await api.postData('/login', { username, password });

            // Check if the response indicates success
            if (response.responseType === 'success') {
                localStorage.setItem('accessToken', response.token);
                toast.success("Login successful");
                navigate('/'); // Route to the dashboard
            } else {
                toast.error('Invalid username or password');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while logging in');
        }
    };

    // Show loading message while checking login status
    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="login-container">
            <div className="form-container">
                <h2>Login</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn-login">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
