// DashboardSection.js
import React, { useState, useEffect } from 'react';
import api from '../api/api'; // Import API utility
import '../styles/DashboardSection.css'; // Import DashboardSection.css

function DashboardSection() {
    const [dashboardData, setDashboardData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.getData('/dashboard');
                if (response.responseType === 'success') {
                    setDashboardData(response.message);
                } else {
                    console.error('Failed to fetch dashboard data:', response.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="dashboard-section">
            <h2>Dashboard</h2>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="dashboard-content">
                    {dashboardData ? (
                        Object.keys(dashboardData).map(key => (
                            <div key={key} className="dashboard-card">
                                <h3>{key}</h3>
                                <p>{dashboardData[key]}</p>
                            </div>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default DashboardSection;
