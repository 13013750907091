// Sidebar.js
import React from 'react';
import '../styles/Sidebar.css'; // Import Sidebar.css

function Sidebar({ onItemClick }) {
    return (
        <aside className="sidebar">
            <ul>
                <li onClick={() => onItemClick('dashboard')}>Dashboard</li>
                <li onClick={() => onItemClick('users')}>User Management</li>
                <li onClick={() => onItemClick('meters')}>Meter Management</li>
                <li onClick={() => onItemClick('statistics')}>Statistics</li>
                <li onClick={() => onItemClick('notifications')}>Notifications</li>
                <li onClick={() => onItemClick('transactions')}>Transactions</li>
                <li onClick={() => onItemClick('reports')}>Reports</li>
                <li onClick={() => onItemClick('about')}>About</li>
            </ul>
        </aside>
    );
}

export default Sidebar;
