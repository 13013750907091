import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import Modal from './Modal'; // Import Modal component
import '../styles/MetersSection.css';
import api from '../api/api'; // Import API utility

function MetersSection() {
    const [meters, setMeters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [newMeter, setNewMeter] = useState({ serialNumber: '', meterNumber: '', location: '' });

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await api.getData('/meters');
                if (response.responseType === 'success') {
                    setMeters(response.meters);
                } else {
                    console.error('Failed to fetch meters:', response.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching meters:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleAddMeter = () => {
        setShowModal(true); // Show the modal when adding a new meter
    };

    const handleSaveMeter = async () => {
        try {
            // Make a POST request to save the new meter
            const response = await api.postData('/meters', newMeter);
            if (response.responseType === 'success') {
                // Fetch updated list of meters after successfully saving
                const updatedMetersResponse = await api.getData('/meters');
                if (updatedMetersResponse.responseType === 'success') {
                    setMeters(updatedMetersResponse.meters);
                    setNewMeter({ serialNumber: '', meterNumber: '', location: '' });
                    setShowModal(false);
                } else {
                    console.error('Failed to fetch updated meters:', updatedMetersResponse.message);
                }
            } else {
                console.error('Failed to save meter:', response.message);
            }
        } catch (error) {
            console.error('Error saving meter:', error);
        }
    };

    return (
        <div className="meters-section">
            <h2>Meters</h2>
            <div className="meter-list">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <div className="add-meter">
                            <button className="add-meter-button" onClick={handleAddMeter}>
                                <FaPlus />
                                Add New Meter
                            </button>
                        </div>
                        <div className="meter-table-container">
                            <table className="meter-table">
                                <thead>
                                <tr>
                                    <th>Serial Number</th>
                                    <th>Meter Number</th>
                                    <th>Location</th>
                                </tr>
                                </thead>
                                <tbody>
                                {meters.map(meter => (
                                    <tr key={meter.id}>
                                        <td>{meter.serial_number}</td>
                                        <td>{meter.meter_number}</td>
                                        <td>{meter.location}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
            {showModal && (
                <Modal
                    title="Add New Meter"
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveMeter}
                >
                    <div className="modal-content">
                        <label>Serial Number:</label>
                        <input
                            type="text"
                            value={newMeter.serialNumber}
                            onChange={e => setNewMeter({ ...newMeter, serialNumber: e.target.value })}
                            required={true}
                        />
                        <label>Meter Number:</label>
                        <input
                            type="text"
                            value={newMeter.meterNumber}
                            onChange={e => setNewMeter({ ...newMeter, meterNumber: e.target.value })}
                            required={true}
                        />
                        <label>Location:</label>
                        <input
                            type="text"
                            value={newMeter.location}
                            onChange={e => setNewMeter({ ...newMeter, location: e.target.value })}
                            required={true}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default MetersSection;