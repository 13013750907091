// Modal.js
import React from 'react';
import '../styles/Modal.css';

function Modal({ title, onClose, onSave, children }) {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <div className="modal-header">
                    <h3>{title}</h3>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    <button className="save-button" onClick={onSave}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default Modal;