import axios from 'axios';

const API_URL = 'https://water.waithakasam.com/api/web'; // Your API base URL

// Function to handle POST requests
export const postData = async (endpoint, data) => {
    try {
        // Ensure that the endpoint has a "/" at the beginning
        const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;

        // Get the accessToken from localStorage or any other source
        const accessToken = localStorage.getItem('accessToken');

        // Set the headers with the accessToken
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json' // Make sure to set appropriate Content-Type
        };

        // Make the POST request with the custom headers
        const response = await axios.post(`${API_URL}${formattedEndpoint}`, data, { headers });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Function to handle GET requests
export const getData = async (endpoint) => {
    try {
        // Ensure that the endpoint has a "/" at the beginning
        const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;

        // Get the accessToken from localStorage or any other source
        const accessToken = localStorage.getItem('accessToken');

        // Set the headers with the accessToken
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        };

        // Make the GET request with the custom headers
        const response = await axios.get(`${API_URL}${formattedEndpoint}`, { headers });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    postData,
    getData,
};
