import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

function WaterUsageBarChart({ data }) {
    if (data.length === 0) {
        return <div>No data available</div>;
    }

    // Extracting dates and volumes from data
    // const dates = data.map(entry => entry.date);
    // const volumes = data.map(entry => entry.volume);

    // Chart settings
    const chartSettings = {
        yAxis: [{ label: 'Volume' }], // Label for y-axis
        series: [{ dataKey: 'volume', label: 'Volume' }], // Data key and label for series
        height: 500, // Height of the chart
        colors: ['#4C337B'], // Color of the bars
    };

    return (
        <div>
            <h2>Water Usage Bar Chart</h2>
            <BarChart
                xAxis={[{ scaleType: 'band', dataKey: 'date' }]} // x-axis settings
                dataset={data} // Dataset for the chart
                {...chartSettings} // Spread chart settings
            />
        </div>
    );
}

export default WaterUsageBarChart;
