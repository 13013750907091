import React, { useState, useEffect } from 'react';
import api from '../api/api';
import WaterUsageBarChart from "./WaterUsageBarChart";
import '../styles/StatisticsSection.css';

function StatisticsSection({ onItemClick }) {
    const [meterNumbers, setMeterNumbers] = useState([]);
    const [selectedMeter, setSelectedMeter] = useState('');
    const [waterUsageData, setWaterUsageData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMeterNumbers = async () => {
            try {
                const response = await api.getData('/meters');
                if (response.responseType === 'success') {
                    const numbers = response.meters.map(meter => meter.meter_number);
                    setMeterNumbers(numbers);
                    if (numbers.length > 0) {
                        // Automatically select the first meter number
                        setSelectedMeter(numbers[0]);
                    }
                } else {
                    setError('Failed to fetch meter numbers: ' + response.message);
                }
            } catch (error) {
                setError('Error fetching meter numbers: ' + error.message);
            }
        };

        fetchMeterNumbers();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedMeter) {
                try {
                    const response = await api.postData('/waterUsage', { meterNumber: selectedMeter });
                    if (response.responseType === 'success') {
                        setWaterUsageData(response.message);
                    } else {
                        setError('Failed to fetch water usage data: ' + response.message);
                    }
                } catch (error) {
                    setError('Error fetching water usage data: ' + error.message);
                }
            }
        };

        fetchData();
    }, [selectedMeter]);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedMeter(selectedValue);
        if (onItemClick) {
            onItemClick(selectedValue);
        }
    };

    return (
        <div>
            <h2>Statistics</h2>
            <div>
                <label htmlFor="meterNumber">Select Meter Number:</label>
                <select id="meterNumber" value={selectedMeter} onChange={handleSelectChange}>
                    {meterNumbers.map(number => (
                        <option key={number} value={number}>{number}</option>
                    ))}
                </select>
            </div>
            {error && <div>Error: {error}</div>}
            <div>
                <h3>Water Usage Chart</h3>
                <WaterUsageBarChart data={waterUsageData} />
            </div>
        </div>
    );
}

export default StatisticsSection;
