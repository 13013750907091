// Header.js
import React from 'react';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';
import {toast} from "react-toastify";

function Header() {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove accessToken from localStorage
        localStorage.removeItem('accessToken');
        toast.success("Logout successful");
        // Reload the page
        navigate("/login");
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="left-section">
                    <div className="title" onClick={() => navigate("/")}>Machakos Waters</div>
                </div>
                <div className="right-section">
                    <div className="user-info">
                        <FaUser className="user-icon" />
                        <div className="greeting">Hello Admin</div>
                    </div>
                    <button className="logout-button" onClick={handleLogout}>
                        <FaSignOutAlt />
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
