import React, { useState } from 'react';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import api from '../api/api';
import '../styles/ReportsSection.css';
import logoImage from '../logo.png';

function ReportsSection({ onItemClick }) {
    const [reportType, setReportType] = useState('Transactions');
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleGenerateReport = async () => {
        if (!reportType || !startDate || !endDate) {
            toast.error('Please select report type and choose start and end dates.');
            return;
        }

        try {
            // Send report data to the API
            const response = await api.postData('/reports', { reportType, startDate, endDate });
            if (response.responseType === 'success') {
                // Generate PDF with data
                try {
                    const doc = new jsPDF();
                    const fileName = `${reportType}_${startDate}_to_${endDate}.pdf`;
                    const pdfTitle = `Report Type: ${reportType}\nFrom: ${startDate}\nTo: ${endDate}\n`;

                    // Insert logo image and Machakos Waters under the title
                    const imgData = logoImage;
                    doc.addImage(imgData, 'PNG', 10, 5, 16, 16);
                    doc.setFontSize(12);
                    doc.text('Machakos Waters', 30, 15);

                    // Customize table title row background color
                    doc.setFillColor('#4C337B');
                    doc.text(pdfTitle, 10, 30);

                    // Check if there is any data to display in the PDF
                    if (response.message.length === 0) {
                        doc.text('\nNo data available for the selected report type and date range.', 10, 40);
                    } else {
                        // Add response message to PDF table
                        const keys = Object.keys(response.message[0]);
                        const values = response.message.map(item => Object.values(item));
                        doc.autoTable({
                            startY: 50,
                            head: [keys],
                            body: values,
                            theme: 'grid',
                            headStyles: {
                                fillColor: '#4C337B',
                                textColor: '#FFF',
                                fontStyle: 'bold',
                            },
                        });
                    }

                    doc.save(fileName);
                    toast.success('PDF generated successfully.');
                } catch (error) {
                    toast.error('Error generating PDF. Please try again.');
                }
            } else {
                toast.error('Failed to submit report:', response.message);
                return;
            }
        } catch (error) {
            toast.error('Error submitting report. Please try again.');
            return;
        }
    };

    return (
        <div>
            <h2>Reports</h2>
            <div className="report-options">
                <Select
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    variant="outlined"
                    displayEmpty
                    className="report-select"
                    required
                >
                    <MenuItem value="" disabled>
                        Select Report Type
                    </MenuItem>
                    <MenuItem value="Transactions">Transactions</MenuItem>
                    <MenuItem value="Users">Users</MenuItem>
                    <MenuItem value="Logs">Logs</MenuItem>
                    <MenuItem value="Meters">Meters</MenuItem>
                    <MenuItem value="WaterConsumption">Water Consumption</MenuItem>
                </Select>
                <TextField
                    id="start-date"
                    label="From"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                />
                <TextField
                    id="end-date"
                    label="To"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    required
                />
                <Button variant="contained" onClick={handleGenerateReport}>Generate</Button>
            </div>
        </div>
    );
}

export default ReportsSection;
