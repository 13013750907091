// ContentPanel.js
import React from 'react';
import '../styles/ContentPanel.css';
import DashboardSection from "./DashboardSection";
import UsersSection from "./UsersSection";
import MetersSection from "./MetersSection";
import StatisticsSection from "./StatisticsSection";
import NotificationsSection from "./NotificationsSection";
import TransactionsSection from "./TransactionsSection";
import ReportsSection from "./ReportsSection"; // Import ContentPanel.css
import AboutSection from "./AboutSection";

function ContentPanel({ currentPage }) {
    return (
        <main className="content-panel">
            {currentPage === 'dashboard' && <DashboardSection />}
            {currentPage === 'users' && <UsersSection />}
            {currentPage === 'meters' && <MetersSection />}
            {currentPage === 'statistics' && <StatisticsSection />}
            {currentPage === 'notifications' && <NotificationsSection />}
            {currentPage === 'transactions' && <TransactionsSection />}
            {currentPage === 'reports' && <ReportsSection />}
            {currentPage === 'about' && <AboutSection />}
        </main>
    );
}

export default ContentPanel;
