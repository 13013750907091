// Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ContentPanel from '../components/ContentPanel';
import api from '../api/api';
import '../styles/Dashboard.css'; // Import Dashboard.css

function Dashboard() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState('dashboard'); // State for current page

    useEffect(() => {
        // Make a GET request to "/login" from the API
        api.getData('/login')
            .then(response => {
                // Check if the response is successful
                if (response.responseType === 'success') {
                    setIsLoading(false); // Set loading to false upon successful response
                } else {
                    // If the response is not successful, route to "/login"
                    navigate("/login");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error); // Set error state in case of an error
                setIsLoading(false); // Set loading to false
                // Route to "/login" in case of an error
                navigate("/login");
            });
    }, [navigate]);

    const handleItemClick = (item) => {
        setCurrentPage(item); // Update current page state when an item is clicked
    };

    if (isLoading) {
        return null; // You can render a loader or any other component while waiting for the response
    }

    if (error) {
        return <div>Error: {error.message}</div>; // Render error message if there's an error
    }

    return (
        <div>
            <Header />
            <div className="main-container">
                <Sidebar onItemClick={handleItemClick} /> {/* Pass onItemClick prop */}
                <ContentPanel currentPage={currentPage} /> {/* Pass currentPage as prop */}
            </div>
        </div>
    );
}

export default Dashboard;
