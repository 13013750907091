// TransactionsSection.js
import React, { useState, useEffect } from 'react';
import api from '../api/api';
import '../styles/TransactionsSection.css';

function TransactionsSection() {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await api.getData('/transactions');
                if (response.responseType === 'success') {
                    setTransactions(response.transactions);
                } else {
                    console.error('Failed to fetch transactions:', response.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    return (
        <div>
            <h2>Transactions</h2>
            <div className="transaction-table-container">
                <table className="transaction-table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Meter Number</th>
                        <th>Paybill Number</th>
                        <th>Token Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan="5">Loading...</td>
                        </tr>
                    ) : (
                        transactions.map(transaction => (
                            <tr key={transaction.id}>
                                <td>{new Date(new Date(transaction.date).getTime() - (3 * 60 * 60 * 1000)).toLocaleString()}</td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.meter_number}</td>
                                <td>{transaction.paybill_number}</td>
                                <td>{transaction.token_amount}</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TransactionsSection;
