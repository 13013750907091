// AboutSection.js
import React from 'react';
import logo from '../logo.png'; // Import the logo image
import '../styles/AboutSection.css'; // Import AboutSection.css

function AboutSection() {
    // Function to launch URL
    const _launchURL = () => {
        const url = 'https://waithakasam.com'; // Your website URL
        window.open(url, '_blank');
    };

    return (
        <div className="about-section">
            <div className="container">
                <h2>About Us</h2>
                <p className="about-text">
                    This project report is submitted in partial fulfillment for the requirement of award of Bachelor of Science in Computer Science of Machakos University.
                </p>
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" /> {/* Use the imported logo variable */}
                </div>
                <div className="footer">
                    <div className="date">December, 2024</div>
                    <div className="divider">|</div>
                    <button className="website-link" onClick={_launchURL}>Visit our website</button>
                </div>
                <p className="copyright">Copyright © 2024 IdrisFallout. All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default AboutSection;
