// NotificationsSection.js
import React, { useState, useEffect } from 'react';
import api from '../api/api';
import '../styles/NotificationsSection.css';

function NotificationsSection() {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await api.getData('/notifications');
                if (response.responseType === 'success') {
                    setNotifications(response.message);
                } else {
                    console.error('Failed to fetch notifications:', response.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setIsLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    return (
        <div>
            <h2>Notifications</h2>
            <div className="notification-container">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    notifications.length === 0 ? (
                        <div className="no-notification-message">There are no notifications at the moment.</div>
                    ) : (
                        <table className="notification-table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Message</th>
                                <th>Meter Number</th>
                            </tr>
                            </thead>
                            <tbody>
                            {notifications.map(notification => (
                                <tr key={notification.id} className={notification.is_error ? 'error' : ''}>
                                    <td>{new Date(new Date(notification.date).getTime() - (3 * 60 * 60 * 1000)).toLocaleString()}</td>
                                    <td>{notification.message}</td>
                                    <td>{notification.meter_number}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )
                )}
            </div>
        </div>
    );
}

export default NotificationsSection;
