// UsersSection.js
import React, { useState, useEffect } from 'react';
import api from '../api/api';
import '../styles/UsersSection.css'; // Import UsersSection.css

function UsersSection({ onItemClick }) {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.getData('/users');
                if (response.responseType === 'success') {
                    setUsers(response.message);
                } else {
                    console.error('Failed to fetch users:', response.message);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching users:', error);
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div>
            <h2>Users</h2>
            <div className="user-table-container">
                <table className="user-table">
                    <thead>
                    <tr>
                        <th>Meter Number</th>
                        <th>User Email</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan="3">Loading...</td>
                        </tr>
                    ) : (
                        users.map(user => (
                            <tr key={user.id}>
                                <td>{user.meter_number}</td>
                                <td>{user.user_email}</td>
                                <td>
                                    <div className={`user-status ${user.active ? 'active' : 'inactive'}`}>
                                        {user.active ? 'Active' : 'Inactive'}
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UsersSection;
